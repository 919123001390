<template>
  <div class="layout">
    <layout-header />
    <div class="layout-main">
      <router-view />
    </div>
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from './components/Header'
import LayoutFooter from './components/Footer'

export default {
  name: 'LayoutMain',
  components: {
    LayoutHeader,
    LayoutFooter
  }
}
</script>

<style></style>
