<template>
  <div class="container-fluid nav-bar">
    <div class="common-nav">
      <div class="common-nav-container">
        <div class="logo">
          <a href="/"><img
            src="https://oss.yonyoubao.com/baoqukeji/logo.svg?v=1676293466"
            alt="保趣科技"
          ></a>
        </div>
        <div class="menu">
          <div class="online">
            <div class="btn" @click="handleOpenProducts">
              <div class="text">在线咨询</div>
              <div class="icon">
                <img
                  src="https://oss.yonyoubao.com/static/images/www/common/icon-down.png?v=1676293466"
                  alt="在线咨询"
                >
              </div>
            </div>
            <div class="search-container">
              <div class="search-center-container">
                <div class="search-filter">
                  <ul class="filter-tabs">
                    <li class="company filter-tab">
                      <div class="filter-title">
                        <div class="title">保险公司</div>
                        <ul class="filter-fast">
                          <li v-for="fastitem in model.company.fast" :key="fastitem.id">
                            <a @click="handleOpen(fastitem.href)">{{ fastitem.name }}</a>
                          </li>
                        </ul>
                      </div>
                      <ul class="filter-all">
                        <li v-for="allitem in model.company.more" :key="allitem.id">
                          <a @click="handleOpen(allitem.href)">{{ allitem.name }}</a>
                        </li>
                      </ul>
                    </li>
                    <li class="instype filter-tab">
                      <div class="filter-title">
                        <div class="title">险种</div>
                        <ul class="filter-fast">
                          <li v-for="fastitem in model.instype.fast" :key="fastitem.id">
                            <a @click="handleOpen(fastitem.href)">{{ fastitem.name }}</a>
                          </li>
                        </ul>
                      </div>
                      <ul class="filter-all">
                        <li v-for="allitem in model.instype.more" :key="allitem.id">
                          <a @click="handleOpen(allitem.href)">{{ allitem.name }}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="logo" style="visibility: hidden">
          <a href="/">
            <img
              src="https://oss.yonyoubao.com/baoqukeji/logo.svg?v=1715752703480"
              alt="保趣科技"
            ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSearchCompayLsit, getSearchTypeLsit } from '@/common/api/product'

export default {
  name: 'ComNavBar',
  data() {
    return {
      form: {
        key: ''
      },
      model: {
        max: 6,
        company: {
          fast: [],
          more: [],
          all: [],
          list: [],
          text: '更多'
        },
        instype: {
          fast: [],
          more: [],
          all: [],
          list: [],
          text: '更多'
        }
      }
    }
  },
  mounted() {
    this.getCompany()
    this.getInstype()
  },
  methods: {
    handleOpenProducts() {
      window.location.href = `/#/products`
    },
    handleOption(type, id) {
      window.location.href = `/#/products?${type}=${id}`
    },
    handleSearch() {
      window.location.href = `/#/products?key=${this.form.key}`
    },
    getCompany() {
      getSearchCompayLsit().then((res) => {
        this.model.company.all = res.map(item => {
          item.href = `/#/products?company=${item.id}`
          item.name = item.name === '安联财险' ? '京东安联财险' : item.name
          return item
        })
        const arr = []
        this.model.company.all.forEach(item => {
          if (item.name.includes('平安')) {
            arr.unshift(item)
          } else {
            arr.push(item)
          }
        })
        this.model.company.fast = arr.slice(0, 2)
        this.model.company.more = this.model.company.all.slice(0, 8)
        this.clickShow('company')
      })
    },
    getInstype() {
      getSearchTypeLsit().then((res) => {
        this.model.instype.all = res.data.map(item => {
          item.href = `/#/products?instype=${item.id}`
          return item
        })
        this.model.instype.fast = this.model.instype.all.slice(0, 3)
        this.model.instype.more = this.model.instype.all.slice(0, 8)
        this.clickShow('instype')
      })
    },
    clickShow(type) {
      if (this.model[type].list.length === this.model.max) {
        this.model[type].list = []
        this.model[type].text = '收起'
        this.model[type].list = this.model[type].all
      } else {
        this.model[type].list = this.model[type].all.slice(0, this.model.max)
        this.model[type].text = '更多'
      }
    },
    handleOpen(path) {
      window.location.href = path
      window.location.reload()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/common/styles/variables.scss";

.nav-bar {
  .common-nav {
    width: 100%;
    height: 128px;
    border-bottom: 1px solid #E9E9E9;

    .common-nav-container {
      height: 128px;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        height: 52px;

        img {
          height: 52px;
        }
      }

      .menu {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .online {
          position: relative;

          .btn {
            width: 148px;
            height: 48px;
            border-radius: 6px;
            font-weight: bold;
            font-size: 16px;
            background-color: $--color-primary;
            text-align: center;
            line-height: 48px;
            color: #fff;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            .text {
              padding-right: 4px;
            }

            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          &:hover {
            .search-container {
              display: block;
            }
          }

          .search-container {
            display: none;
            z-index: 999;
            position: absolute;
            top: 48px;
            left: 0;
            transform: translateX(-400px);
            padding-top: 40px;
            width: 1000px;

            .search-center-container {

              position: relative;
              background-color: #ffffff;
              box-sizing: border-box;
              border: 1px solid $--color-primary;
              border-top-width: 8px;

              &::after {
                content: ' ';
                height: 20px;
                width: 20px;
                background-color: $--color-primary;
                transform: rotate(45deg);
                position: absolute;
                top: -16px;
                left: 454px;
                z-index: -1;
              }

              .search-filter {
                font-size: 16px;
                line-height: 32px;
                width: 100%;

                & > ul {
                  width: 100%;
                  overflow: hidden;
                  position: relative;

                  & > li {

                    &:after {
                      content: ' ';
                      display: block;
                      clear: both;
                    }

                    &:last-child {
                      .filter-title {
                        border-bottom: none;
                      }
                    }

                    &:first-child {
                      .filter-title {
                        z-index: 10;
                      }

                      .filter-all {
                        z-index: 9;
                        display: flex;
                      }
                    }

                    &:hover {
                      .filter-title {
                        z-index: 100;
                      }

                      .filter-all {
                        display: flex;
                        z-index: 99;
                      }
                    }

                    .filter-title {
                      flex: none;
                      width: 240px;
                      border-bottom: 1px solid $--color-primary;
                      padding: 14px 20px;
                      background-color: #ffffff;
                      position: relative;

                      .title {
                        padding-bottom: 5px;
                        font-size: 16px;
                        color: #232323;
                      }

                      .filter-fast {
                        display: flex;

                        li {
                          padding-right: 16px;
                          color: #666;
                        }
                      }
                    }

                    .filter-all {
                      display: none;
                      position: absolute;
                      top: 0;
                      left: 279px;
                      box-sizing: border-box;
                      padding: 30px;
                      width: 718px;
                      height: 100%;
                      background-color: #ffffff;
                      border-left: 1px solid $--color-primary;

                      flex: auto;
                      // display: flex;
                      flex-wrap: wrap;

                      li {
                        height: 50px;
                        width: 150px;
                        text-align: center;
                        line-height: 50px;
                        margin: 5px;
                        border: 1px solid #f2f2f2;

                        &:hover {
                          border-color: $--color-primary;
                        }

                        &:last-child {
                          margin-right: 0;
                        }
                      }

                    }

                    //&>ul {
                    //  flex: auto;
                    //  display: flex;
                    //  flex-wrap: wrap;
                    //
                    //  li {
                    //    margin-right: 10px;
                    //
                    //    &:last-child {
                    //      margin-right: 0;
                    //    }
                    //  }
                    //}

                    a {
                      font-size: 16px;
                      font-weight: 400;
                      color: #767676;
                      cursor: pointer;

                      &:hover {
                        color: #E50012;
                      }
                    }
                  }
                }
              }
            }
          }

        }

        & > ul {
          display: flex;

          & > li {
            margin-left: 40px;
          }

          a {
            display: block;
            height: 42px;
            line-height: 42px;
            font-size: 16px;
            color: #767676;
            box-sizing: border-box;

            &:hover,
            &.active {
              color: #000000;
              font-weight: bold;
              border-bottom-style: solid;
              border-bottom-width: 2px;
              border-bottom-color: #E50012;
            }
          }
        }
      }
    }

  }
}
</style>
