<template>
  <transition name="fade">
    <div class="login-form-contanner">
      <div class="com-login-form">
        <el-form
          ref="loginForm"
          :model="form"
          class="login-form"
          autocomplete="on"
          label-position="left"
        >

          <div class="title-container">
            <h3 class="title">验证码登录</h3>
            <div class="btn-close" @click="fail">
              <i class="el-icon-close" />
            </div>
          </div>

          <el-form-item prop="mobile" class="form-item" :error="model.error.mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号码"
              type="text"
              @input="inputChange"
              @blur="mobileBlur"
            >
              <el-select
                slot="prepend"
                v-model="model.select"
                placeholder="请选择"
                disabled
                style="width: 80px"
              >
                <el-option label="+86" value="1" />
              </el-select>
            </el-input>

          </el-form-item>
          <el-form-item prop="captcha" class="form-item">
            <div class="captcha">
              <el-input
                v-model="form.captcha"
                class="captcha-input"
                placeholder="请输入验证码"
                type="text"
                @input="captchaChange"
              />
              <div class="captcha-img">
                <div class="captcha-img-box">
                  <template v-if="model.src">
                    <img :src="model.src" alt="图形验证码">
                  </template>
                  <template v-else>
                    <div class="icon">
                      <i class="el-icon-loading" />
                    </div>
                  </template>
                </div>
                <div v-if="model.src" class="captcha-img-refresh" @click="refreshImgCaptcha">
                  <i class="el-icon-refresh" />
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="pwd" class="form-item" :error="model.error.pwd">
            <div class="sms-code">
              <el-input
                v-model="form.pwd"
                class="sms-code-input"
                placeholder="请输入验证码"
                type="text"
                @input="pwdChange"
              />
              <el-button
                class="sms-code-btn"
                :disabled="model.sms.disabled"
                @click="sendSMSCode"
              >
                <i v-if="model.sms.loading" class="el-icon-loading" />
                {{ model.sms.text }}
              </el-button>
            </div>

          </el-form-item>

          <el-form-item>
            <el-checkbox v-model="model.checked">我已阅读并同意</el-checkbox>
            <span class="span-link">用户协议</span>
            <span style="color: rgb(49, 129, 249);">、</span>
            <span class="span-link">隐私政策</span>
          </el-form-item>

          <el-button
            type="primary"
            :icon="model.icon"
            :loading="model.loading"
            :disabled="model.disabled"
            @click.native.prevent="handleLogin"
          >
            {{ model.loading ? '登录中' : '登录' }}
          </el-button>

        </el-form>
        <div class="login-help">
          <p class="flex">
            新用户使用短信登录自动注册保趣科技账号
          </p>
        </div>
        <div v-if="showClose" class="login-close" @click="fail"><i class="el-icon-close" /></div>
      </div>

    </div>
  </transition>
</template>

<script>
// 登录逻辑在此处是实现，登录页面和投保前未登录调用此组件登录

import { v4 as uuidv4 } from 'uuid'
import { getCaptcha, getInfo, getSMSCode, login } from '@/api/user'
import { setToken } from '@/utils/auth'
import { isMobile } from '@/common/utils/validate/mobile'

export default {
  name: 'ComLoginForm',
  data() {
    return {
      timer: null,
      showClose: false,
      loading: {
        captcha: false
      },
      model: {

        loading: false,
        checked: false,
        select: '1',
        src: '',
        icon: '',
        disabled: true,
        sms: {
          disabled: true, // 发送短信的按钮，防止多次点击
          time: 60, // 下次发送短信倒计时
          text: '获取验证码', // 发送短信按钮的文字,
          loading: false // 网络请求等待期间加载动画
        },
        error: {
          mobile: '',
          pwd: ''
        },
        captcha: {
          length: 5
        }
      },
      form: {
        uuid: '',
        mobile: '',
        captcha: '',
        pwd: ''
      }
    }
  },
  watch: {
    'model.checked': function(n, o) {
      this.model.disabled = !n
    }
  },
  mounted() {
    this.form.uuid = uuidv4()
    this.getImgCaptcha()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    inputChange() {
      const res = isMobile(this.form.mobile)
      this.model.error.mobile = this.form.mobile.length >= 11 ? res.msg : ''
      this.model.sms.disabled = !(res.state && this.form.captcha.length === this.model.captcha.length)
    },
    captchaChange() {
      if (this.form.captcha.length > this.model.captcha.length) {
        this.form.captcha = this.form.captcha.slice(0, this.model.captcha.length)
      }
      this.inputChange()
    },
    mobileBlur() {
      const res = isMobile(this.form.mobile)
      this.model.error.mobile = res.msg
    },
    pwdChange() {
      this.model.error.pwd = ''
    },
    // 登录流程 - 获取图片验证码 - 验证图片验证码并发送短信验证码 - 提交手机号和短信验证码登录
    // 获取验证码
    getImgCaptcha() {
      const params = {
        uuid: this.form.uuid
      }
      getCaptcha(params).then(res => {
        const herf = window.URL.createObjectURL(new Blob([res]))
        this.model.src = herf
      })
    },
    // 验证图片验证码并发送短信验证码
    sendSMSCode() {
      const params = {
        phone: this.form.mobile,
        captcha: this.form.captcha,
        uuid: this.form.uuid
      }
      this.model.sms.loading = true
      this.model.sms.disabled = true
      this.countDown()
      this.model.sms.loading = false
      getSMSCode(params).then(res => {
        console.log(res)
      }).finally(() => {

      })
    },
    // 提交手机号和短信验证码登录
    handleLogin() {
      // 校验是否有验证码
      if (!this.form.pwd) {
        this.model.error.pwd = '请填写短信验证码'
        return null
      }
      this.model.loading = true
      const params = {
        phone: this.form.mobile,
        validationCode: this.form.pwd
      }
      login(params).then(res => {
        setToken(res)
        // 获取用户信息
        getInfo({ _: new Date().getTime() }).then(user => {
          const userInfo = user.userInfo
          if (!userInfo.handurl) {
            userInfo.handurl = '/images/common/default-avatar.png'
          }
          // 检测用户登录使用
          sessionStorage.setItem('user', JSON.stringify(user.userInfo))
          // 用户跨站检测
          localStorage.setItem('user', JSON.stringify(user.userInfo))
          this.$message.success('登录成功')
          this.success()
        })
      }).catch(() => {
        this.refreshImgCaptcha()
      }).finally(() => {
        this.model.loading = false
      })
    },
    // 刷新验证码
    refreshImgCaptcha() {
      this.form.uuid = uuidv4()
      this.model.src = ''
      this.getImgCaptcha()
    },
    //
    success() {
    },
    fail() {
      this.$destroy()
      // this.$destroy()不会销毁DOM, 需对DOM进行处理
      document.querySelector('.login-form-contanner').remove()
    },
    countDown() {
      this.timer = setInterval(() => {
        this.model.sms.time--
        this.model.sms.text = `重新发送 ${this.model.sms.time}`
        if (this.model.sms.time === 0) {
          this.model.sms.text = '重新发送验证码'
          this.model.sms.time = 60
          this.model.sms.disabled = false
          this.refreshImgCaptcha()
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    }
  }
}
</script>
<style lang="scss">
.login-form-contanner {

  .el-input {
    height: 52px;

    input {
      height: 52px;
      font-size: 16px;
    }
  }

}
</style>
<style scoped lang="scss">
@import "@/common/styles/variables.scss";

$--input-height: 52px;

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.login-form-contanner {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.com-login-form {
  background-color: #ffffff;
  padding: 30px;
  position: relative;
  border-radius: 6px;

  .login-form {
    width: 400px;

    .form-item {
      height: $--input-height;
    }

    .title-container {
      width: 100%;
      margin-bottom: 40px;

      .title {
        color: #333;
        font-size: 18px;

      }
      .btn-close {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $--color-grey;
        cursor: pointer;
        &:hover {
          color: $--color-primary;
        }
      }

    }

    .captcha {
      width: 100%;
      position: relative;

      .captcha-input {
      }

      .captcha-img {
        position: absolute;
        right: 4px;
        top: 4px;
        width: 176px;

        display: flex;
        box-sizing: border-box;
        overflow: hidden;

        &:hover {
          border-color: $--color-primary;

          .captcha-img-refresh {
            visibility: visible;
          }
        }

        .captcha-img-box {
          height: 44px;
          width: 176px;

          img {
            height: 44px;
          }

          .icon {
            width: 100%;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;

          }
        }

        .captcha-img-refresh {
          visibility: hidden;
          position: absolute;
          width: 100%;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.4);

          .el-icon-refresh {
            font-size: 22px;
            color: $--color-text-placeholder;
          }

          &:hover {
            .el-icon-refresh {
              color: $--color-primary;
            }
          }
        }
      }
    }
  }

  .sms-code {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .sms-code-input {
      flex: auto;
      margin-right: 22px;
    }

    .sms-code-btn {
      flex: none;
      width: 180px;
    }
  }

  .span-link {
    color: $--color-primary;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .el-button {
    width: 100%;
    height: $--input-height;
  }

  .login-help {
    width: 100%;
    padding-top: 10px;

    & > p {
      display: block;
      width: 100%;
      font-size: 12px;
      color: $--color-text-placeholder;
      line-height: 24px;
      text-align: center;
    }
  }
}

is-radius {
  border-radius: 8px;
}

.login-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #bbb;
  cursor: pointer;

  &:hover {
    color: $--color-primary;
  }
}
</style>
