import Cookies from 'js-cookie'

const TokenKey = 'yyb-token'

export function getToken() {
  return Cookies.get(TokenKey) || sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  sessionStorage.setItem(TokenKey, token)
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  sessionStorage.removeItem(TokenKey)
  return Cookies.remove(TokenKey)
}
