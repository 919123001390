import request from '@/utils/request'
// 获取产品保险公司
export function getInsCompany(query) {
  return request({
    url: '/ins/business/rest/common/select/insuranceCompanyAll',
    method: 'get',
    params: query
  })
}
// 获取产品类别
export function getInsType(query) {
  return request({
    url: '/ins/business/rest/systemParameters/codeList/1902',
    method: 'get',
    params: query
  })
}
// 获取产品列表
export function getInsProducts(query) {
  return request({
    url: '/ins/business/rest/product/newQueryProductAll',
    method: 'get',
    params: query
  })
}
// 获取保险详情 保险参与计算信息
export function getInsDetail(path) {
  return request({
    url: `/ins/business/rest/zhyw/insurance/premium/getProductInfo/${path}`,
    method: 'get'
  })
}
// 获取产品详情 保险展示信息
export function getProductDetail(path, query) {
  return request({
    url: `/ins/business/rest/zhyw/insurance/premium/getProductOtherInfo/${path}`,
    method: 'get',
    params: query
  })
}

// 计算产品费率
export function calcPremium(data) {
  return request({
    url: '/ins/business/rest/zhyw/insurance/premium/getPremium',
    method: 'post',
    data
  })
}

// 获取身份证类型
export function getIdTypes(path) {
  return request({
    url: `/ins/business/rest/systemParameters/companyCerttypeList/${path}`,
    method: 'get'
  })
}
// 获取职业类别
export function getProfessionTypes(path) {
  return request({
    url: `/ins/business/rest/common/select/occupation/${path}`,
    method: 'get'
  })
}
// 计算保费
export function getInsPremium(data) {
  return request({
    url: `/ins/business/rest/zhyw/insurance/premium/getPremiumSum`,
    method: 'post',
    data
  })
}
// 保存保费信息
export function saveInsPremium(data) {
  return request({
    url: `/ins/business/rest/zhyw/insurance/order/submitOrder`,
    method: 'post',
    data
  })
}

// 获取当前机构可编辑的产品
export function getOrgProductEditList(query) {
  return request({
    url: `/ins/business/rest/product/dept/plan/query`,
    method: 'get',
    params: query
  })
}
// 获取当前机构可编辑的产品
export function saveOrgProductEditList(data) {
  return request({
    url: `/ins/business/rest/product/dept/plan/input/editPlan`,
    method: 'put',
    data
  })
}
// 给机构分配未添加产品
export function addOrgProductEditList(data) {
  return request({
    url: `/ins/business/rest/product/dept/plan/input/addPlan`,
    method: 'put',
    data
  })
}
// 获取当前机构可给下级配置的产品列表
export function getOrgEnableProductList(query) {
  return request({
    url: `/ins/business/rest/product/dept/plan/queryToAdd`,
    method: 'get',
    params: query
  })
}
// 上传被保人
export function fileUpload(data) {
  return request({
    url: `/ins/business/rest/uploadimport/personnel`,
    method: 'post',
    data,
    header: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 取出分享暂存数据
export function getShareData(path) {
  return request({
    url: `/ins/business/rest/shareShort/getDataInfo/${path}`
  })
}
// 分享产品，暂存数据，到商城取出数据
export function getShareCode(data) {
  return request({
    url: `/ins/business/rest/shareShort/getShareShort`,
    method: 'post',
    data
  })
}
// 分享产品，暂存数据，到商城取出数据
export function getTravelProductsInfo(path) {
  return request({
    url: `/ins/business/rest/product/queryOrgPlanByProductId/${path}`,
    method: 'get'
  })
}
// 分享产品，暂存数据，到商城取出数据
export function getProductsTitleInfo(path) {
  return request({
    url: `/ins/business/rest/productInfo/getTopInfo/${path}`,
    method: 'get'
  })
}
export function getProductsInfo(path) {
  return request({
    url: `/ins/business/rest/productInfo/getInfo/${path}`,
    method: 'get'
  })
}

// 获取搜索公司快捷跳转
export function getSearchCompayLsit() {
  return request({
    url: '/ins/business/rest/common/select/insuranceCompanyAll',
    method: 'get'
  })
}
// 获取搜索类型快捷跳转
export function getSearchTypeLsit() {
  return request({
    url: '/ins/business/rest/common/insurcanceType/findFristType',
    method: 'get'
  })
}

// 暂存用户选择数据
export function createStoreData(data) {
  return request({
    url: '/ins/business/rest/online/enquiry/storeData',
    method: 'post',
    data
  })
}

