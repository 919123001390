// index.js
import Vue from 'vue'
import LoginForm from './index.vue'

// 定义一个函数
let vm = null
const LoginFormComponent = function(options) {
  // 设置默认值
  const defaults = {
    showClose: false,
    success: null,
    fail: null
  }
  // 通过Vue.extend（）函数，创建一个Vue构造器 Component
  const Component = Vue.extend(LoginForm)
  // 将传入的值赋值给默认值
  for (const key in options) {
    defaults[key] = options[key]
  }

  // 利用解构赋值定义变量
  const {
    showClose
  } = defaults
  // 实例化构造器
  vm = new Component({
    // 如果没有挂载的话，没有关联的 DOM 元素。是获取不到$el的。
    // 或者new Component({...}).$mount('#main')
    el: document.createElement('div'), // 挂载方式之一
    data() {
      return {
        showClose
      }
    },
    methods: {
      success() {
        // 如果传入了默认的确认方法，则defaults.handleConfirm为真，执行defaults.handleConfirm.call(this)
        defaults.success && defaults.success.call(this)
        document.body.removeChild(vm.$el)
      },
      fail() {
        defaults.fail && defaults.fail.call(this)
        document.body.removeChild(vm.$el)
      }
    }
  })
  // 将组件插入body中
  document.body.appendChild(vm.$el)
}
export default LoginFormComponent
