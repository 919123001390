import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/',
    redirect: '/home',
    hidden: true,
    component: Layout,
    children: [
      {
        name: 'Home',
        path: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue'),
        meta: { title: '保趣科技', icon: '' }
      },
      {
        name: 'ProductList',
        path: 'products',
        component: () => import(/* webpackChunkName: "home" */ '@/views/products/list.vue'),
        meta: { title: '产品列表-保趣科技', icon: '' }
      }
    ]
  },
  {
    path: '/products',
    component: Layout,
    children: [{
      path: 'group',
      name: 'Group',
      component: () => import(/* webpackChunkName: "ProductGroup" */ '@/layout/product.vue'),
      hidden: true,
      children: [
        {
          path: 'detail',
          component: () => import(/* webpackChunkName: "ProductGroup" */ '@/views/products/group/detail'),
          name: 'GroupDetails',
          hidden: true,
          meta: { title: '团体意外险-详情', icon: '' }
        },
        {
          path: 'order',
          component: () => import(/* webpackChunkName: "ProductGroup" */ '@/views/products/group/order'),
          name: 'GroupOorder',
          hidden: true,
          meta: { title: '团体意外险-订单详情', icon: '' }
        }
      ]
    }, {
      path: 'ht03ig', // 体育健身运动意外险
      name: 'Ht03IG',
      component: () => import(/* webpackChunkName: "ProductHt03IG" */ '@/layout/product.vue'),
      hidden: true,
      children: [
        {
          path: 'detail',
          component: () => import(/* webpackChunkName: "ProductHt03IG" */ '@/views/products/ht03ig/detail'),
          name: 'Ht03IGDetails',
          hidden: true,
          meta: { title: '体育健身运动意外险-详情', icon: '' }
        },
        {
          path: 'order',
          component: () => import(/* webpackChunkName: "ProductHt03IG" */ '@/views/products/ht03ig/order'),
          name: 'Ht03IGOorder',
          hidden: true,
          meta: { title: '体育健身运动意外险-订单详情', icon: '' }
        }
      ]
    }, {
      path: 'travel', // 体育健身运动意外险
      name: 'ProductTravel',
      component: () => import(/* webpackChunkName: "ProductHt03IG" */ '@/layout/product.vue'),
      hidden: true,
      children: [
        {
          path: 'detail',
          component: () => import(/* webpackChunkName: "ProductHt03IG" */ '@/views/products/travel/index.vue'),
          name: 'TravelDetails',
          hidden: true,
          meta: { title: '旅游险-详情', icon: '' }
        },
        {
          path: 'order',
          component: () => import(/* webpackChunkName: "ProductHt03IG" */ '@/views/products/ht03ig/order'),
          name: 'TravelOrder',
          hidden: true,
          meta: { title: '体育健身运动意外险-订单详情', icon: '' }
        }
      ]
    }]
  }
]

export const asyncRoutes = [// 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: constantRoutes
})

router.beforeEach(async(to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
