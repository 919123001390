// 虚拟网络运营商
const MVNO = [
  {
    company: '中国电信',
    no: ['1700', '1701', '1702', '162']
  },
  {
    company: '中国移动',
    no: ['1703', '1705', '1706', '165']
  },
  {
    company: '中国联通',
    no: ['1704', '1707', '1708', '1709', '171', '167']
  }
]

// 移动网络运营商
const MNO = [
  {
    company: '中国移动',
    no: [
      '134',
      '135',
      '136',
      '137',
      '138',
      '139',
      '147',
      '150',
      '151',
      '152',
      '157',
      '158',
      '159',
      '178',
      '182',
      '183',
      '184',
      '187',
      '188',
      '198'
    ]
  },
  {
    company: '中国电信',
    no: ['133', '153', '173', '177', '180', '181', '189', '191', '199']
  },
  {
    company: '中国联通',
    no: [
      '130',
      '131',
      '132',
      '155',
      '156',
      '166',
      '175',
      '176',
      '185',
      '186'
    ]
  },
  {
    company: '中国广电',
    no: [
      '192'
    ]
  }
]

export function isMobile(mobile, type = 'all') {
  const reslut = {
    state: false,
    source: mobile,
    msg: ''
  }
  // 非空和长度验证
  if (!mobile) {
    reslut.msg = '手机号不能为空'
    return reslut
  }
  if (mobile.length !== 11) {
    reslut.msg = '手机号长度不正确'
    return reslut
  }
  // 非数字校验
  const reg = /^[0-9]{11}$/
  if (!reg.test(mobile)) {
    reslut.msg = '手机号不能包含字母'
    return reslut
  }

  // 验证是否是运营商号段
  const mobilePrefix = []
  MNO.forEach(item => {
    for (let i = 0; i < item.no.length; i++) {
      mobilePrefix.push(item.no[i])
    }
  })
  // 检测是否验证虚拟网络运营商
  if (type === 'all') {
    MVNO.forEach(item => {
      for (let i = 0; i < item.no.length; i++) {
        if (!mobilePrefix.includes(item.no[i].substring(0, 3))) {
          mobilePrefix.push(item.no[i].substring(0, 3))
        }
      }
    })
  }
  // 170 是全号段，所以只要校验前三位即可
  const prefix = mobile.substring(0, 3)
  if (!mobilePrefix.includes(prefix)) {
    reslut.msg = '手机号非中国大陆号码，请更换号码'
    return reslut
  }
  reslut.state = true
  reslut.msg = ''
  return reslut
}
