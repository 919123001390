import request from '@/utils/request'

/*
 * 登录接口
 * @params Object {phone:String,validationCode:String}
 */

export function login(params) {
  return request({
    url: '/ins/web/rest/common/login/phoneLogin',
    method: 'get',
    params: params
  })
}

/*
 * 获取图形验证码
 * @params Object {uuid:String}
 */
export function getCaptcha(params) {
  return request({
    url: '/ins/web/rest/common/login/getCaptcha',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

/*
 * 获取短信验证码
 * @params Object {phone:String,captcha:String,uuid:String}
 */
export function getSMSCode(params) {
  return request({
    url: '/ins/web/rest/common/login/validatcode',
    method: 'get',
    params: params
  })
}
// 获取用户信息
export function getInfo(query) {
  return request({
    url: '/ins/web/rest/common/commonDatas',
    method: 'get',
    params: query
  })
}
// 退出登录
export function logout(query) {
  return request({
    url: '/ins/web/rest/common/login',
    method: 'delete',
    params: query
  })
}
// 注册
export function regiter(query) {
  return request({
    url: '/ins/web/rest/common/login',
    method: 'delete',
    params: query
  })
}

// 注册时的行业类别
export function industry(query) {
  return request({
    url: '/ins/business/rest/online/enquiry/findOnlineIndustry',
    method: 'get'
  })
}
