<template>
  <div class="layout-header">
    <nav-bar />
  </div>
</template>

<script>
import NavBar from '../NavBar'

export default {
  name: 'LayoutHeader',
  components: {
    NavBar
  }
}
</script>

<style></style>
