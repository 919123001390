<template>

  <div class="common-footer">
    <p>
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备17002227号</a>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502008191">粤公网安备 44030502008191号</a>
    </p>
    <p>版权所有 @2017 深圳市保趣科技有限公司</p>
    <p>联系我们：400-168-3036</p>
  </div>

</template>

<script>

export default {
  name: 'LayoutFooter'
}
</script>

<style scoped lang="scss">

.common-footer {
  height: 148px;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 28px;
  color: #E5E5E5;

  a {
    color: #E5E5E5;
    text-decoration: none;
    cursor: pointer;
  }
  p > a:first-child {
    margin-right: 20px;
  }
}

</style>
