import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/common/styles/reset.css'
import ElementUI from 'element-ui'
import '@/common/styles/theme.css'
import '@/common/styles/global.css'

Vue.config.productionTip = false

ElementUI.Dialog.props.closeOnPressEscape.default = false
Vue.use(ElementUI, {
  size: 'medium' // set element-ui default size
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
